<template>
<a-layout class="setting">
    <a-layout-content>
        <a-row v-bind:class="[ _mobile  ? '' : 'pc' ]">
            <a-col :span="span" :offset="offSet" >
                <div style="display: table;margin: 0 auto;    max-width: 394px;   width: 92%;">
                  <div>
                    <div class="s-setting-b">등급관리</div>
                    <div class="s-setting-b1 " v-bind:class="{ active: level ==0 }" @click="onSelectLicenseType(0)">
                        <div style="margin-left:20px;margin-top:16px;margin-right:20px;margin-bottom:6px">
                            <i class="fal " v-bind:class="[ level ==0  ? 'fa-check-circle' : 'fa-circle' ]"></i>&nbsp;<span class="s-setting-font1">체험판<div class="s-setting-use" v-if="originalLevel == 0" style="margin-left: 70px; margin-top: -25px; position: absolute; padding-left: 5px;padding-top: 0px;">이용중</div></span>
                        </div>
                        <div class="s-settting-b3">
                            <span class="f1" style="float:left">선택 할 수 있는 관심 제품 수</span> <span class="f2" style="float:right;">최대 5개</span><br />
                        </div>
                        <div class="s-settting-b3">
                            <span class="f1" style="float:left">가격</span> <span class="f3" style="float:right">7일간 무료</span><br />
                        </div>
                        <div class="s-settting-b3" v-if="level==0 && originalLevel == level">
                            <span class="f1" style="float:left">만료일자</span> <span class="f2" style="float:right">{{getExpireDate}}</span><br />
                        </div>
                    </div>

                    <div class="s-setting-b1 " v-bind:class="{ active: level ==1 }" @click="onSelectLicenseType(1)" style="margin-top:10px">
                        <div style="margin-left:20px;margin-top:16px;margin-right:20px;margin-bottom:6px">
                            <i class="fal " v-bind:class="[ level ==1  ? 'fa-check-circle' : 'fa-circle' ]"></i>&nbsp;<span class="s-setting-font1">스탠다드<div class="s-setting-use" v-if="originalLevel == 1" style="margin-left: 90px; margin-top: -25px; position: absolute; padding-left: 5px;padding-top: 0px;">이용중</div></span>
                        </div>
                        <div class="s-settting-b3">
                            <span class="f1" style="float:left">선택 할 수 있는 관심 제품 수</span> <span class="f2" style="float:right;">최대 100개</span><br />
                        </div>
                        <div class="s-settting-b3">
                            <span class="f1" style="float:left">가격</span> <span class="f3" style="float:right">월 9,900원</span><br />
                        </div>
                        <div class="s-settting-b3" v-if="level==1 && originalLevel == level">
                            <span class="f1" style="float:left">만료일자</span> <span class="f2" style="float:right">{{getExpireDate}}</span><br />
                        </div>
                    </div>

                    <div class="s-setting-b1 " v-bind:class="{ active: level ==2 }" @click="onSelectLicenseType(2)" style="margin-top:10px">
                        <div style="margin-left:20px;margin-top:16px;margin-right:20px;margin-bottom:6px">
                            <i class="fal " v-bind:class="[ level ==2  ? 'fa-check-circle' : 'fa-circle' ]"></i>&nbsp;<span class="s-setting-font1">프리미엄<div class="s-setting-use" v-if="originalLevel == 2" style="margin-left: 90px; margin-top: -25px; position: absolute; padding-left: 5px;padding-top: 0px;">이용중</div></span>
                        </div>
                        <div class="s-settting-b3">
                            <span class="f1" style="float:left">선택 할 수 있는 관심 제품 수</span> <span class="f2" style="float:right;">최대 300개</span><br />
                        </div>
                        <div class="s-settting-b3">
                            <span class="f1" style="float:left">가격</span> <span class="f3" style="float:right">월 29,900원</span><br />
                        </div>
                        <div class="s-settting-b3" v-if="level==2 && originalLevel == level">
                            <span class="f1" style="float:left">만료일자</span> <span class="f2" style="float:right">{{getExpireDate}}</span><br />
                        </div>
                    </div>

                    <div class="s-setting-b1 " v-bind:class="{ active: level ==3 }" @click="onSelectLicenseType(3)" style="margin-top:10px">
                        <div style="margin-left:20px;margin-top:16px;margin-right:20px;margin-bottom:6px">
                            <i class="fal " v-bind:class="[ level ==3  ? 'fa-check-circle' : 'fa-circle' ]"></i>&nbsp;<span class="s-setting-font1">엔터프라이즈<div class="s-setting-use" v-if="originalLevel == 3" style="margin-left: 120px; margin-top: -25px; position: absolute; padding-left: 5px;padding-top: 0px;">이용중</div></span>
                        </div>
                        <div class="s-settting-b3">
                            <span class="f1" style="float:left">선택 할 수 있는 관심 제품 수</span> <span class="f2" style="float:right;">300이상</span><br />
                        </div>
                        <div class="s-settting-b3">
                            <span class="f1" style="float:left">가격</span> <span class="f3" style="float:right">별도협의</span><br />
                        </div>
                        <div class="s-settting-b3" v-if="level==3 && originalLevel == level">
                            <span class="f1" style="float:left">만료일자</span> <span class="f2" style="float:right">{{getExpireDate}}</span><br />
                        </div>
                    </div>
                    <div class="s-setting-b2 " v-bind:class="{ active: level !=originalLevel && level !=3 }" @click="onChangeGrade()" style="margin-top:10px">
                        등급 변경
                    </div>
                    <div class="s-setting-b" style="margin-top:14px">
                        결제수단
                    </div>
                    <div>
                        <a-select v-model="selectedMid" style="max-width: 374px;width:100%" @change="handelSelectNewCardType">
                            <a-select-option :value="item.mid" v-for="item in cardlist" :key="item.mid">{{item.title}}</a-select-option>
                        </a-select>
                    </div>
                    <div class="s-setting-b2 active" style="margin-top:10px" v-if="selectedMid!='-'" @click="onAddNewCard()">
                        결제수단 변경
                    </div>
                    <div class="s-setting-b2 active" style="margin-top:10px" v-if="selectedMid=='-'" @click="onAddNewCard()">
                        카드 추가
                    </div>
                    <div class="s-setting-b" style="margin-top:14px">
                        쿠폰 관리
                    </div>
                    <div class="s-setting-b5" style="text-align:center" v-if="couponData.length == 0">
                        <div style="margin-top:60px">
                            <img src="@/assets/images/default-coupon.png" style="width:32px;height:20px" />
                        </div>
                        <div style="margin-top:5px">
                            <span>가지고 계신 쿠폰이 없어요</span>
                        </div>
                    </div>
                    <div  v-else>
                      <div class="s-setting-b5" style="margin-bottom:10px;padding:20px" v-for="item in couponData" :key="item.id">
                        <div class="s-setting-font4">{{item.remain}}일 남음</div>
                        <div class="s-setting-font5 truncate-overflow">{{item.name}}</div>
                        <a-divider />
                        <div>
                          <span class="s-setting-font6">할인명수</span><span class="s-setting-font7" style="float:right">{{item.c1}}명</span>
                        </div>
                        <div>
                          <span class="s-setting-font6">사용기간</span><span class="s-setting-font7"  style="float:right">{{item.period}}</span>
                        </div>


                      </div>


                    </div>
                    <div class="s-setting-b" style="margin-top:14px">
                        보유 예치금 [{{deposit}}]
                    </div>
                  </div>
                </div>
            </a-col>
        </a-row>

        <a-modal v-model="dialogVisible" title="등급 변경">
            <template slot="footer">
                <a-button key="back" @click="dialogVisible=false">
                    취소
                </a-button>
                <a-button key="submit"  @click="onHandleChangeGrade()">
                    변경
                </a-button>
            </template>
            <span>등급을 <span style="color:#0264fb">{{cur[level].title}}</span>으로 변경하시겠습니까?<br />
                <span v-if="level!=0">(선택되어있는 결제수단에서 자동결제가 이루어집니다.)</span></span>
        </a-modal>
        <a-modal v-model="dialogVisible2" title="카드 추가" :width="374">
            <template slot="footer">
                <a-button key="back" @click="dialogVisible2=false">
                    취소
                </a-button>
                <a-button key="submit" class="regBtn"  v-bind:class="[ confirm1&&confirm2  ? 'enabled' : '' ]" :disabled="!confirm1||!confirm2" @click="onHandleAddCard()">
                    등록
                </a-button>
            </template>
            <div style="margin-top: -20px;">
                <span class="s-setting-font2">카드 번호</span>
                <a-input type="text" v-mask="'####-####-####-####'" placeholder="카드번호를 입력해주세요" v-model="nCard"> </a-input>
            </div>
            <div style="margin-top:16px;display:flex">
                <div style="margin-right:14px">
                    <span class="s-setting-font3">사업자 구분</span>
                </div>
                <div>
                    <a-radio-group v-model="ctype" name="radioGroup" :default-value="1">
                        <a-radio :value="1">
                            개인
                        </a-radio>
                        <a-radio :value="2">
                            개인사업자 / 법인사업자
                        </a-radio>
                    </a-radio-group>
                </div>
            </div>
            <div style="margin-top:16px">
                <span class="s-setting-font3">카드정보 입력</span>
            </div>

            <div style="margin-top:10px" v-if="ctype ==1">
                <a-input type="text" v-mask="'######'" placeholder="생년월일 6 자리 입력" v-model="nPrivate"> </a-input>
            </div>
            <div style="margin-top:10px" v-else>
                <a-input type="text" v-mask="'##########'" placeholder="사업자번호 10자리 입력" v-model="nBuissness"> </a-input>
            </div>
            <div style="margin-top:10px">
                <a-input type="text" v-mask="'####-##'" placeholder="만기일 입력 (YYYY-MM)" v-model="nExpire"> </a-input>
            </div>
            <div style="margin-top:10px">
                <a-input type="password" v-mask="'##'" placeholder="패스워드 앞 두자리" v-model="nPwdDigit"> </a-input>
            </div>
             <div style="margin-top:10px">
                 <a-checkbox v-model="confirm1">카드정보 저장 </a-checkbox>
            </div>
             <div style="margin-top:2px">
                 <a-checkbox v-model="confirm2">  스토어링크 자동결제에 동의합니다. </a-checkbox>
            </div>

        </a-modal>
    </a-layout-content>
    <nav-footer></nav-footer>
</a-layout>
</template>

<script>
Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

import {
  getLicense,
  replaceAll,
  getCurrentTime,
  isMobile,
  showError,
  showSuccess,
  dateDiffInDays
} from "../components/fnc.js";
import firebase from "firebase";
import NavFooter from "../../layouts/nav/NavFooter";
export default {
  components: {
    NavFooter
  },
  data() {
    return {
      confirm1: false,
      confirm2: false,
      span: 8,
      offSet: 8,
      dialogVisible: false,
      dialogVisible2: false,
      couponData: [],
      tableData: [],
      sysid: "",
      ctype: 1,
      cNo: "",
      nCard: "",
      nBuissness: "",
      nPrivate: "",
      nExpire: "",
      nPwdDigit: "",
      cardlist: [],
      cardInfo: {
        cardType: "",
        cardNo: "",
        expireDay: "",
        pwd2digit: "",
        num: ""
      },
      cur: [
        {
          level: "0",
          title: "체험판",
          desc: "1-5개",
          pricetxt: "0원",
          price: 0,
          limit: 5
        },
        {
          level: "1",
          title: "스탠다드",
          desc: "6-100개",
          pricetxt: "월 9,900원",
          price: 9900,
          limit: 100
        },
        {
          level: "2",
          title: "프리미엄",
          desc: "101-300개",
          pricetxt: "월 29,900원",
          price: 29900,
          limit: 300
        },
        {
          level: "3",
          title: "앤터프라이즈",
          desc: "300개이상",
          pricetxt: "별도협의",
          price: 59900,
          limit: 1000
        }
      ],
      selectedMid: "-",
      loading: false,
      level: 0,
      originalLevel: 0,
      deposit: 0
    };
  },
  mounted() {
    this.originalLevel = getLicense();
    this.level = getLicense();
    this.onLoadSubdscribe();
    this.onLoadCoupon();
    this.onLoadDeposit();

    if (this.$route.params.level != undefined) {
      this.onSelectLicenseType(this.$route.params.level);
    }
    document.getElementById("fullroot").scrollTop = 0;
    fbq("track", "PageView");
  },
  computed: {
    getCreatedTime() {
      return getCurrentTime(
        new Date(this.$store.state.AppActiveUser.createdAt)
      );
    },
    getMargin() {
      return this.$store.state.AppActiveUser.remain > 0
        ? this.$store.state.AppActiveUser.remain
        : 0;
    },
    getExpireDate() {
      return getCurrentTime(
        new Date().addDays(this.$store.state.AppActiveUser.remain)
      );
    },
    getName() {
      return this.$store.state.AppActiveUser.displayName;
    },
    getUid() {
      return this.$store.state.AppActiveUser.uid;
    },
    getEmail() {
      return this.$store.state.AppActiveUser.email;
    },
    getUserKey() {
      return this.$store.state.AppActiveUser.key;
    },
    getMerchatUid() {
      return this.$store.state.AppActiveUser.uid + "_" + new Date().getTime();
    },
    _mobile: function() {
      var _mobile = this.$vssWidth < 1024 ? true : isMobile();
      this.span = _mobile ? 24 : 8;
      this.offSet = _mobile ? 0 : 8;
      return _mobile;
    }
  },
  methods: {
    onAddNewCard() {
      this.dialogVisible2 = true;
    },
    handelSelectNewCardType(e) {
      this.selectedMid = e;
    },
    onHandleChangeGrade(e) {
      this.dialogVisible = false;
      this.changeGrade();
    },
    onCardValidation() {
      if (this.selectedMid != "-") {
        return true;
      } else if (
        this.selectedMid == "-" &&
        this.cardInfo.num != "" &&
        this.cardInfo.expireDay != "" &&
        this.cardInfo.pwd2digit != ""
      ) {
        return true;
      } else if (this.level == 0) {
        return true;
      } else {
        return false;
      }
    },
    onChangeGrade() {
      if (this.level == this.originalLevel || this.level == 3) {
        return false;
      } else {
        if (
          (this.selectedMid == "-" && this.cardInfo.num) ||
          this.selectedMid != "-"
        ) {
          this.dialogVisible = true;
        } else {
          if (this.level == 0) {
            this.dialogVisible = true;
          } else {
            showError({
              notify: this.$vs.notify,
              msg: "카드를 먼저 추가 해주시기 바랍니다."
            });
          }
        }
      }
    },
    onHandleAddCard() {
      this.selectedMid = "-";
      this.cardlist = [];
      this.cardlist.push({
        mid: this.selectedMid,
        title: "****-****-****-" + replaceAll(this.nCard, "-", "").substring(12)
      });
      this.dialogVisible2 = false;

      this.cardInfo = {
        cardType: this.ctype,
        cardNo: replaceAll(this.nCard, "-", ""),
        expireDay: this.nExpire,
        pwd2digit: this.nPwdDigit,
        num: this.ctype == 1 ? this.nPrivate : this.nBuissness
      };

      this.ctype = 1;
      this.nCard = "";
      this.nPrivate = "";
      this.nBuissness = "";
      this.nExpire = "";
      this.nPwdDigit = "";
    },
    changeGrade() {
      var self = this;
      if (this.onCardValidation()) {
        if (this.level > 0) {
          var _title =
            this.originalLevel < this.level
              ? "등급 상향 조정 하시겠습니까?"
              : "등급 하향 조정 하시겠습니까?";
          this.$confirm({
            title: _title,
            content:
              "등급 조정에 따른 결제 비용은 다음 결제일에 반영이 됩니다.",
            okText: "변경",
            cancelText: "취소",
            onOk() {
              self.$vs.loading();
              //In case of new
              if (self.selectedMid == "-") {
                //In case of new
                if (self.cardInfo.num != "") {
                  //In case of change or new card
                  self.deleteSchedule();
                  self.sendInfo();
                  if (self.originalLevel == 0) {
                    self.onInitCountForPaidUser();
                  }
                } else {
                  self.$vs.loading.close();

                  showError({
                    notify: self.$vs.notify,
                    msg: "카드 정보가 없습니다"
                  });
                }
              } else {
                //Just Upgrade
                self.deleteSchedule();
                self.setSchedule();
                if (self.originalLevel == 0) {
                  self.onInitCountForPaidUser();
                }
              }
            },
            onCancel() {}
          });
        } else if (this.level == 0) {
          this.$confirm({
            title: "정기결제 정보를 삭제 하시겠습니까?",
            content:
              "기존 사용중인 관심 제품수량은 더이상 접근을 하실수 없습니다.",
            okText: "변경",
            cancelText: "취소",
            onOk() {
              self.$vs.loading();
              self.deleteSchedule();
              self.setUserInfo(-30);
            },
            onCancel() {}
          });
        }
      } else {
        showError({
          notify: this.$vs.notify,
          msg: "결제 정보를 입력해주세요"
        });
      }
    },
    onInitCountForPaidUserNaver() {
      var sref = firebase.database().ref("auto_execution_master/");

      sref
        .orderByChild("uid")
        .equalTo(this.getUid)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            var uref = firebase
              .database()
              .ref("auto_execution_master/" + sdatas.key);

            var upData = {
              count: 0,
              mark: "-"
            };

            uref.update(upData);
          });
        });
    },
    onInitCountForPaidUserKakao() {
      var sref = firebase.database().ref("auto_execution_master_kakao/");

      sref
        .orderByChild("uid")
        .equalTo(this.getUid)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            var uref = firebase
              .database()
              .ref("auto_execution_master_kakao/" + sdatas.key);

            var upData = {
              count: 0,
              mark: "-"
            };

            uref.update(upData);
          });
        });
    },
    onInitCountForPaidUserCoupang() {
      var sref = firebase.database().ref("auto_execution_master_coupang/");

      sref
        .orderByChild("uid")
        .equalTo(this.getUid)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            var uref = firebase
              .database()
              .ref("auto_execution_master_coupang/" + sdatas.key);

            var upData = {
              count: 0,
              mark: "-"
            };

            uref.update(upData);
          });
        });
    },
    onInitCountForPaidUserTmon() {
      var sref = firebase.database().ref("auto_execution_master_tmon/");

      sref
        .orderByChild("uid")
        .equalTo(this.getUid)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            var uref = firebase
              .database()
              .ref("auto_execution_master_tmon/" + sdatas.key);

            var upData = {
              count: 0,
              mark: "-"
            };

            uref.update(upData);
          });
        });
    },
    onInitCountForPaidUser() {
      this.onInitCountForPaidUserNaver();
      this.onInitCountForPaidUserKakao();
      this.onInitCountForPaidUserCoupang();
      this.onInitCountForPaidUserTmon();
    },
    sendInfo() {
      var self = this;
      this.$http
        .post(
          "https://us-central1-store-link-49fa1.cloudfunctions.net/genbillingwithpay",
          {
            card_number: this.cardInfo.cardNo,
            expiry: this.cardInfo.expireDay,
            birth: this.cardInfo.num,
            buyer_name: this.getName,
            buyer_email: this.getEmail,
            pwd_2digit: this.cardInfo.pwd2digit,
            customer_uid: this.getUid,
            amount: this.cur[this.level].price,
            merchant_uid: this.getMerchatUid
          }
        )
        .then(function(x) {
          if (x) {
            if (x.data.status == "failed") {
              self.$vs.loading.close();

              showError({
                notify: self.$vs.notify,
                msg: x.data.message
              });
            } else {
              self.setSchedule();
            }
          }
        })
        .catch(function(error) {
          console.log("Remove failed: " + error.message);
        });
    },
    setUserInfo(due) {
      var self = this;
      var _margin = due < 0 ? 0 : this.getMargin;
      var uref = firebase.database().ref("user/" + this.getUserKey);
      var d2 = new Date();
      var d = getCurrentTime(new Date());

      var upData = {
        expire: _margin + due,
        dirtyAt: d2.getTime(),
        eventdttm: d,
        maxlimit: this.cur[this.level].limit
      };

      // var _a = self.$store.state;

      // _a.level = this.cur[this.level].limit;
      // this.$store.commit("setter", _a);

      uref
        .update(upData)
        .then(function() {
          self.$vs.loading.close();
          let secondsToGo = 5;
          const modal = self.$success({
            title: "성공적으로 등급이 변경되었습니다.",
            content: `이 창은 ${secondsToGo} 초 후에 자동으로 없어집니다.`,
            onOk() {
              self.$router.push("/pages/home");
            }
          });
          const interval = setInterval(() => {
            secondsToGo -= 1;
            modal.update({
              content: `이 창은 ${secondsToGo} 초 후에 자동으로 없어집니다.`
            });
          }, 1000);
          setTimeout(() => {
            clearInterval(interval);
            modal.destroy();
            self.$router.push("/pages/home");
          }, secondsToGo * 1000);
        })
        .catch(function(error) {
          alert("Data could not be saved." + error);
        });
    },
    deleteSchedule() {
      var self = this;
      if (self.sysid) {
        var d2 = new Date();
        var d = getCurrentTime(new Date());

        var sref = firebase.database().ref("subscribe/" + self.sysid + "/");
        sref.remove();

        var newPostKey2 = firebase
          .database()
          .ref()
          .child("user_payment/")
          .push().key;

        firebase
          .database()
          .ref("user_payment/" + newPostKey2)
          .set({
            uid: self.getUid,
            createdAt: d2.getTime(),
            eventdttm: d,
            reverseCreatedAt: -d2.getTime(),
            expire: 0,
            email: self.getEmail,
            card: "",
            payment: "",
            paymenttype: self.cur[self.level].limit,
            imp_uid: self.sysid
          })
          .then(function(result) {});
      }
    },
    setSchedule() {
      var self = this;
      var d2 = new Date();
      var d = getCurrentTime(new Date());

      firebase
        .database()
        .ref("subscribe/" + this.getUid)
        .set({
          uid: this.getUid,
          userkey: this.getUserKey,
          createdAt: d2.getTime(),
          expireday: d2.addDays(29).getTime(),
          eventdttm: d,
          reverseCreatedAt: -d2.getTime(),
          name: this.getName,
          email: this.getEmail,
          paymenttype: this.cur[this.level].title,
          amount: this.cur[this.level].price,
          merchant_uid: this.getMerchatUid,
          customer_uid: this.getUid,
          card_number: this.cardInfo.cardNo,
          status: "대기"
        })
        .then(function(result) {
          var newPostKey2 = firebase
            .database()
            .ref()
            .child("user_payment/")
            .push().key;

          firebase
            .database()
            .ref("user_payment/" + newPostKey2)
            .set({
              uid: self.getUid,
              createdAt: d2.getTime(),
              eventdttm: d,
              reverseCreatedAt: -d2.getTime(),
              expire: 30,
              email: self.getEmail,
              card: self.cardInfo.cardNo,
              payment: self.cur[self.level].price,
              paymenttype: self.cur[self.level].title,
              imp_uid: self.getMerchatUid
            })
            .then(function(result) {
              self.setUserInfo(30);
            });
        });
    },
    onLoadCoupon() {
      var self = this;
      var sref = firebase.database().ref("coupon_user/");

      this.couponData = [];

      sref
        .orderByChild("uid")
        .equalTo(this.getUserKey)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            var _remain = dateDiffInDays(
              new Date(sdatas.val().end.substr(0, 10))
            );
            var _t = {
              id: sdatas.key,
              remain: _remain,
              date: sdatas.val().flag == "Y" ? sdatas.val().date : "-",
              name: sdatas.val().name,
              c1: sdatas.val().c1,
              status: sdatas.val().flag == "Y" ? "사용함" : "미사용",
              period:
                sdatas.val().start.substr(0, 10) +
                " ~ " +
                sdatas.val().end.substr(0, 10)
            };
            if (sdatas.val().flag == "Y" || _remain < 0) {
              //skip
            } else {
              self.couponData.push(_t);
            }
          });
        });
    },
    onLoadDeposit() {
      var self = this;
      var sref = firebase.database().ref("user/");

      sref
        .orderByChild("email")
        .equalTo(this.getEmail)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            var _deposit = sdatas.val().deposit ? sdatas.val().deposit : 0;
            self.deposit = _deposit.toLocaleString() + "원";
          });
        });
    },
    onLoadSubdscribe() {
      var self = this;
      var sref = firebase.database().ref("subscribe/");

      self.cardlist = [];

      sref
        .orderByChild("email")
        .equalTo(this.getEmail)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            self.sysid = sdatas.key;
            //   self.licensetype = 1;
            //   self.nextthing = getCurrentTime(new Date(sdatas.val().expireday));

            // self.cNo = sdatas.val().card_number;
            self.selectedMid = sdatas.val().merchant_uid;

            self.cardInfo = {
              cardNo: sdatas.val().card_number
            };

            self.cardlist.push({
              mid: sdatas.val().merchant_uid,
              title:
                "****-****-****-" +
                replaceAll(sdatas.val().card_number, "-", "").substring(12)
            });
          });
        })
        .then(() => {
          // if (self.$store.state.level < 16) {
          //   self.nextthing = self.$store.state.remain + "일";
          // } else if (self.licensetype == 2) {
          //   self.nextthing = self.$store.state.remain + "일";
          // }
        })
        .catch(function(error) {
          console.log("Error getting documents: ", error);
        });
    },
    onSelectLicenseType(e) {
      this.level = e;
    }
  }
};
</script>

<style>
.s-settting-b3 {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.s-settting-b3 .f1 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  color: #7d7d7d;
}

.s-settting-b3 .f2 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  text-align: right;
  color: #000000;
}

.s-settting-b3 .f3 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  text-align: right;
  color: #0264fb;
}

.s-setting-font1 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.13px;
  color: #000000;
}

.s-setting-font2 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: -0.58px;
  color: #7d7d7d;
}

.s-setting-font3 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.58px;
  color: #7d7d7d;
}

.s-setting-font4 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.75px;
  color: #7d7d7d;
}

.s-setting-font5 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.13px;
  color: #000000;
}

.s-setting-font6 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  color: #7d7d7d;
}

.s-setting-font7 {
  font-family: "Muli";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  text-align: right;
  color: #000000;
}

.text-style-1 {
  font-family: NotoSansKR;
}

.s-setting-b {
  margin-top: 60px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.38px;
  color: #000000;
  padding-bottom: 10px;
}

.s-setting-b1 {
  cursor: pointer;
  width: 100%;
  max-width: 374px;
  height: 119px;
  border-radius: 5px;
  border: solid 1px #dbdbdb;
  background-color: #ffffff;
}

.s-setting-b1.active {
  height: 139px;
  border: solid 1px #0264fb;
}

.s-setting-b2 {
  cursor: not-allowed;
  width: 100%;
  max-width: 374px;
  height: 40px;
  border-radius: 5px;
  background-color: #bfbfbf;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  color: #ffffff;
  text-align: center;
  padding-top: 10px;
}

.s-setting-b5 {
  max-width: 374px;
  width: 100%;
  height: 153px;
  border-radius: 5px;
  border: solid 1px #dbdbdb;
  background-color: #ffffff;
}

.s-setting-b2.active {
  cursor: pointer;
  background-color: #0264fb;
}

.s-setting-use {
  width: 51px;
  height: 22px;
  border-radius: 3px;
  border: solid 1px #0264fb;
  background-color: rgba(2, 100, 251, 0.05);
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  color: #0264fb;
}

.setting .ant-divider {
  margin-top: 10px;
  margin-bottom: 10px;
}

.setting .truncate-overflow {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.regBtn.ant-btn.enabled {
  background-color: #0264fb !important;
  border-color: #0264fb !important;
  color: white;
}
</style>
